<template>
  <div class="container-fluid">
    <ElixioWalletTransactionsManager payment-gateway="DPA-PPA"/>
  </div>
</template>

<script>
import ElixioWalletTransactionsManager from "@/components/ElixioWallet/ElixioWalletTransactionsManager.vue";
export default {
  layout: "DashboardLayout",
  components:{
    ElixioWalletTransactionsManager
  }
};
</script>