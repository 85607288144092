<template>
  <div class="container-fluid">
    <all-transactions-list :payment-gateway="paymentGateway" show-global ref="alltransactionList" />
  </div>
</template>

<script>
import Vue from "./main";
import AllTransactionsList from "./components/lists/AllTransactionsList.vue";
export default {
  name: "ElixioWalletTransactionsManager",
  components: {
    AllTransactionsList,
  },
  props: {
    paymentGateway: {
      type: String,
      default: null,
    },
    allmeta: {
      type: String,
      default: null,
    },
  },
  data() {
    return {

    };
  },
  created() {

  },
};
</script>
