<template>
  <div class="amount-badge">
    <span 
      class="amount font-weight-bold" 
      :class="{'text-green': !isOut, 'text-red': isOut}">
      {{ formattedAmount }}
    </span>
  </div>
</template>

<script>
export default {
  name: "AmountBadge",
  props: {
      type:{
        type:String,
        required:false,
        default:'deposit'
      },
    amount: {
      type: [Number,String],
      required: true
    },
    symbol: {
      type: String,
      default: '$'
    },
  },
  computed: {
    isOut() {
      if(this.type=='withdraw'){
        return true;
      }
      return this.amount < 0;
    },
    formattedAmount() {
      const decimalPlaces = (this.amount.toString().split('.')[1] || "").length;
      let sign = this.amount < 0 ? '- ' : '+ ';
      if(this.type=='withdraw'){
        sign = '- ';
      }
      return `${sign}${this.symbol}${Math.abs(this.amount).toFixed(decimalPlaces)}`;
    }
  },
};
</script>